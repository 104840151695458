.content-sets-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    overflow: auto;


    .images-container {
        width: 100%;
        align-items: center;
        justify-content: center;
        max-height: 330px;
        overflow: auto;
    }

    .uploaded-files-container {
        max-height: 40%;
        overflow: auto;

        .uploaded-files {
            display: block;
            font-weight: bold;
        }
    }

    .manage-content-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-family: 'Helvetica', sans-serif;
        font-size: 40px;
        font-weight: 300;
        z-index: 300;
        color: white;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        cursor: pointer;
    
        .white-button {
            border-radius: 15px;
            background-color: white;
            margin-top: 8px;
            margin-right: 12px;
            margin-bottom: 12px;
            width: fit-content;
        }
    }
}

.preview-box {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    padding: 0px 2%;
    background: #fff;
    font-size: 14px;

    .image {
        height: 100px;
        width: 100%;
        object-fit: cover;
        border-radius: 4px;
        margin-left: 5px;
        margin-right: 5px;
    }
    
    .image-loading {
        opacity: 0;
        transition: none;
    }
}
