.audio-input-container {
    display: flex;
    width: 75%;
    margin: 0px 2%;
    position: relative;
    justify-content: flex-start;

    .audio-input-label {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Helvetica', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: white;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        cursor: pointer;
    }

    .preview-container {
        display: flex;
        justify-content: flex-end;
        position: relative;
        width: 100%;
        z-index: 1;

        .audio-player
        {
            display: flex;
            flex-direction: column;
            text-align: left;
            
            .file-name
            {
                margin-bottom: 10px;
            }
            .react-audio-player{
                outline: none;
                width: 100%;
                min-width: 300px;
            }
        }

        .preview-status {
            display: flex;
            align-content: space-between;
            align-items: center;

            .linear-progress-container {
                display: block;
                margin-left: 8px;
                margin-right: 8px;
            }

            .preview-filename {
                font-family: 'Helvetica', sans-serif;
                font-size: 14px;
                font-weight: 400;
                color: #333333;
            }

            .preview-button {
                background-size: 14px 14px;
                background-position: center;
                background-repeat: no-repeat;
                width: 14px;
                height: 20px;
                cursor: pointer;
                opacity: 0.9;
                margin: 0 0 2px 10px;
            }
        }
    }
}