.table-header {
    text-align: left;
    margin: 15px 30px;
}

.table-container {
    display: flex;

    .MuiBox-root {
        width: 100%;

        .header-container {
            border-bottom: 1px solid black;
            flex-direction: row;
            display: flex;
            justify-content: space-between;
        }

        .search-container {
            position: relative;
            background-color: white;
            border: 0.5px solid black;
            width: 80%;
            margin: 5px auto;

            .search-icon {
                padding: 2px;
                height: 100%;
                position: absolute;
                pointer-events: none;
                display: flex;
                align-items: center;
                justify-content: center,
            }
        }

        .table-content-container {
            overflow-y: auto;
            height: 80vh;
        }
    }
}