@import '../../assets//globalVariables.scss';

.button-container{
    .green-button{
        border-radius: 15px;
        background-color: $brandColor !important;
        color: white;
    }
    .red-button{
        border-radius: 15px;
        background-color: rgb(209, 67, 56) !important;
        color: white;
        margin-left: 8px;
        margin-right: 8px;
    }
}