@import "../../assets//globalVariables.scss";

.export-report-container {
  width: 420px;
  display: flex;
  margin: 20px;
  height: auto;
  flex-direction: column;
  border-radius: 20px;
  background-color: white;
  text-align: center;
  justify-content: center;

  .error-box {
    font-size: 14px;
    text-align: center;
    color: red;
  }

  .select-label {
    display: inline-block;
    margin-right: 30px;
    margin-top: 20px;
  }

  .row-group {
    display: flex;
    flex-direction: row;
  }

  .pdf-link {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: center;
  }

  .button-group {
    display: flex;
    flex-direction: row;
    padding-top: 20px;

    .submit-button {
      width: 150px !important;
      height: 35px;
      letter-spacing: 0.5px;
      line-height: 35px;
      padding: 0 20px 0 20px;
      font-size: 10px;
      background-color: $brandColor !important;
      color: white !important;
      font-family: "Open Sans Condensed";
      border-radius: 25px !important;
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
      display: flex;
      justify-content: center;
    }

    .cancel-button {
      @extend .submit-button;
      background-color: rgb(160, 160, 160);
    }
  }
}
.action-button {
  margin: 0.5rem !important;
}

.report-status {
  color: white;
  width: 70px;
  border-radius: 25px;
  padding: 0.2rem 0.4rem;
  text-align: center;
  font-size: 0.8rem;
}

.initiate-status {
  background-color: green;
}

.undo-status {
  background-color: rgb(249, 128, 36);
}

.delete-status {
  background-color: rgb(243, 50, 40);
}

.hide-status {
  background-color: rgb(57, 60, 247);
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.modal{
    min-width: 300px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.modal-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.modal-title{
    font-weight: bold;
    font-size: 1.2rem;
}

.card {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
