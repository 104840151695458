@import '../../assets//globalVariables.scss';

.edit-metadata-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between; 
        height: 100%;

    .content-vertical-bar {
        width: 20% !important;
        height: 100vh !important;

        .content-set-label{
            .MuiInputBase-root{
                background-color: white !important;
            }
        }

        .vertical-bar-buttons {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 2%;
            margin: 2%;
        }

        .image-container {
            flex-shrink: 0;
            max-width: 100%;
            max-height: 100%;
        }

        .cover-photo-title{
            position: absolute;
            top: 8px;
            right: 16px;
            background: #ffffff94;
            padding: 2px 8px;
        }
    }

    .content-image-metadata {
        width: 40%;
        height: auto;

        .breadcrumb-container {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        .edit-card-button-group {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            margin: 0 auto;

            .green-button {
                border-radius: 15px;
                background-color: $brandColor !important;
                color: white;
            }
            
        }

        .header-label {
            margin: 0 auto;
            font-weight: bold;
        }
    }
}

.form-field-row {
    display: flex;
    margin: 3px;
    padding: 3px 4px;
}

.button-link {
    color: black;
    font-size: 0.7rem;
    float: right;
    margin: 5px 4px !important;
    
    &:hover {
        cursor: pointer;
        color: black;
    }
}

.accordion-details {
    width: 100%;
    border: 0;
    margin: 0;
    display: flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
}

.tag-input-container {
    display: inline-block;
    width: 100%;

    .tag-input-container > * {
        margin: 15px 0;
    }
}

.MuiListItem-root.Mui-selected.blink {
    background: yellow !important;
    animation: blink 1s infinite;
}

.rejectedContentStatus {
    position: absolute;
    top: 8px;
    right: 16px;
    background: #ffffff94;
    padding: 2px 8px;
}

@keyframes blink {
    0% { background-color: rgb(245, 0, 87) !important; }
    50% { background-color: yellow !important; }
    100% { background-color: rgb(245, 0, 87) !important; }
}

.dropdown-option-container{
    max-width: 500px !important;
    min-width: 300px !important;
}

.dropdown-option{
    margin: 0;
    padding: 0.5rem !important;
    cursor: pointer !important;
}

.dropdown-option:hover{
    background-color: $brandColor;
    color: white !important;
}