.add-article-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100%;

    // .sun-editor-container {
    //     display: flex;
    //     flex-grow: 1;
    //     flex-direction: column;
    //     justify-content: space-between;
    //     height: 95%;

    //     .sun-editor {
    //         margin: 1%;
    //         border: 2px solid gray;
    //         width: 98% !important;
    //         height: 95%;

    //         .se-wrapper {
    //             height: 70%;

    //             .se-wrapper-inner {
    //                 height: 100% !important;
    //             }
    //         }
    //     }
    // }

    .text-field-container
    {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        .sun-editor {
            border: 2px solid gray;
            border-radius: 15px;
            outline: none;

            .se-container {
                margin-top:0;
                background: #dfdfdf;    
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                // padding:5px 0px;

                .se-toolbar {
                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                    outline: none;
                }

                .se-resizing-bar {
                    border-bottom-left-radius: 15px;
                    border-bottom-right-radius: 15px;
                }
            
                .se-wrapper {
                    height: 30vh;
                    text-align: left;

                    .se-wrapper-inner {
                        height: 100% !important;
                    }
                }
            }
        }
    }

    .button-group-article {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin: 3% 0px;

        .upload-input {
            display: none;
        }

        .white-button {
            white-space: nowrap;
            text-align: center;
            border-radius: 15px;
            background-color: white;
            font-weight: bold;
        }
    }

    .article-header {
        position: relative;

        .save-button {
            text-align: center;
            white-space: nowrap;
            border-radius: 15px;
            background-color: white;
            font-weight: bold;
            position: absolute;
            bottom: 15px;
            right: 0;
        }
    }
}

.article-tabs-container {
    border-radius: 20px;
    flex-grow: 1;
    background-color: white;
}