.navigation-header {
  display: flex;
  justify-content: space-between;
  height: 60px;
  background-color: rgb(214, 208, 208);
  // box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
  
  .navigation-heading {
    font-weight: bold;
    align-items: center;
    font-size: 24px;
    padding-left: 12px;
    display: flex;
    justify-content: left;


    &:hover {
      cursor: pointer;
    }
  }

  .navigation-profile {
    display: flex;
    justify-content: flex-end;
    margin: 12px;
  }

}
#userInfo
{
  pointer-events: none; 
  background: #E9E9E9;
  font-size: 70%;
  min-width: 20opx;
}
.userSpan
{
  padding-right:30px;
}
.roleSpan
{
  color: #AFAFAF;
}