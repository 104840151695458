@import '../../assets/globalVariables.scss';
.content-upload-container {
    display: flex;
    align-items: flex-start;

    .container-left {
        margin-left: 10px;
        margin: 12px;
        width: 25%;

        .upload-information {
            margin: 15px;
            text-align: left;

            .header-label {
                margin:8% 2%;
                font-weight: bold;
                font-size: large;
            }

            .header-text{
                margin:8% 2%;
                text-align:justify;
                font-size:15px;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                
            }

            .submission-white-button {
                border-radius: 15px;
                margin-right: 8px;
                margin-bottom: 8px;
                background-color: white;
                width:100%;
                text-transform: none;
                font-size: revert;
            }

            .submission-green-button {
                border-radius: 15px;
                margin-right: 8px;
                margin-bottom: 8px;
                background-color: $brandColor;
                width:100%;
                text-transform: none;
                font-size: revert;
                font-weight: bold;
            }

            .button-group-progress {
                display: flex;
                justify-content: space-between;
                margin-top: 12px;

                .white-button {
                    border-radius: 15px;
                    margin-right: 8px;
                    background-color: white !important;
                    font-weight: bold;
                    text-transform: none;
                }

                .green-button {
                    border-radius: 15px;
                    background-color: $brandColor !important;
                    color: white;
                    font-weight: bold;
                    text-transform: none;
                }
            }

            .loading-indicator {
                text-align: center;
                margin-top: 24px;
                margin-bottom: 24px;
                position: relative;

                .loader-message {
                    margin: 8%;
                    font-size: 1em;
                    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                }
            }
        }
    }

    .stepper-container {
        width: 75%;
        display: flex;
        flex-direction: column;
    }
}