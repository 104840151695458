@import '../../assets/globalVariables.scss';

.edit-waiting-list-container {
    width: auto;
    
    .button-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 2%;
    
        .white-button {
            border-radius: 15px;
            margin-right: 8px;
            background-color: white;
        }
        
        .green-button {
            border-radius: 15px;
            background-color: $brandColor !important;
            color: white;
        }
    
        .red-button {
            border-radius: 15px;
            background-color: rgb(209, 67, 56) !important;
            color: white;
        }

        .download-button{
            background-color: $brandColor;
            width: 150px;
            border-radius: 20px;
            color: white;
            text-decoration: none;
        }
    }
}
