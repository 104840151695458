@import '../../assets/globalVariables.scss';
.section-title{
    font-weight: bold;
    font-size: 1.5rem;
}

.wi-commission-box{
    min-width: 300px;
    width: 50%;
    margin-right: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.default-commissions-box{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;

}

.override-commissions-box{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;
}

.pill-button{
    border-radius: 15px;
    background-color: $brandColor !important;
    color: white !important;
    width: 100px !important;
    height: 40px;
    padding: 0 1rem;
    border: none;
    cursor: pointer;
}

.disabled-pill-button{
    border-radius: 15px;
    background-color: rgb(187, 187, 187) !important;
    color: rgb(84, 84, 84) !important;
    width: 100px !important;
    height: 40px;
    padding: 0 1rem;
    border: none;
    cursor: pointer;
}