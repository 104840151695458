.info-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top:10px;

    .results-container {
        width: 45%;
        display: flex;
        margin-left: 15px;
    }
    .refetch-container {
        width: 55%;
        margin-bottom: 10px;
        display: flex;


        .fetching-data-text {
            font-size: 20px;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        }
    }
}
.MuiAutocomplete-popper{
    min-width: fit-content !important;
    padding: 5px !important;
}
