@import '../../assets/globalVariables.scss';

.errorDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.text{
    color: $brandColor;
    text-align: center;
}
.large{
    font-size: 10rem;
    margin-top: 4rem !important;
    margin-bottom: 0;
}
.small{
    font-size: 3rem;
}
