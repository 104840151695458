@import '../../assets//globalVariables.scss';

.add-user-container {
    width: 420px;
	display: flex;
	margin: 20px;
	height: auto;
	flex-direction: column;
	border-radius: 20px;
	background-color: white;
	text-align: center;
	justify-content: center;
	
	.add-user-form {
		text-align: left;
		display: inherit;
		flex-direction: column;
		justify-content: center;
		width: 70%;
		margin: auto;

		.button-group {
			display: flex;
			flex-direction: row;
			padding-top: 20px;
			.submit-button {
				width: 120px;
				height: 35px;
				letter-spacing: 0.5px;
				line-height: 35px;
				padding: 0 20px 0 20px;
				font-size: 10px;
				background-color: $brandColor;
				color: white;
				font-family: 'Open Sans Condensed';
				border-radius: 25px;
				margin-top: 20px;
				margin-bottom: 20px;
				margin-left: auto;
				margin-right: auto;
				cursor: pointer;
				display: flex;
				justify-content: center;
			}
			
			.cancel-button {
				@extend .submit-button;
				background-color: rgb(160, 160, 160);
			}
		}
	}
    
    .select-label {
        display: inline-block;
		margin-right: 10px;
		margin-top: 10px;
	}
	
	.error-box {
		font-size: 14px;
		text-align: center;
		color: red;
	}
}
.close-button {
    top: 15px; 
    left: 85%; 

    .icon-button {
        color:$brandColor;
    }
}
.MuiAppBar-colorPrimary
{
	color:black;
	background: white;
}
.mebership-type
	{
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		.button-wrapper
		{
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.text-field-wrapper
		{
			display: flex;
			flex-direction: column;
		}
	}
.delete-plan
{
	padding: 12px 16px !important;
}