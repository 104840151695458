.slider-container{
    width: 500px;
    height: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slider-content{
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.slider-image-container{
    width: 100%;
    height: 80%;
}

.image-counter{
    font-weight: bold;
}

.slider-image {
  width: 100%;
  height: 100% !important;
  max-width: 700px;
  max-height: 600px;
  object-fit: contain;
}

.slider-button{
    width: 50px;
    height: 50px;
}

.loader-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}